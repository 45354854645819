

function UploadFiles( whistle, $, params ){

	var me = this;

	me.clickUploadLink = function( ev ){
		// debugger
		$('.uploadFiles' + me.elementToSelectorSuffix(this)).click();
		// $('.uploadFiles').click();
		ev.preventDefault();
		$(this).blur();
	};

	me.elementToSelectorSuffix = function(element){
		var namespace = $(element).data('namespace');
		var suffix = '';
		if(namespace){
			suffix = '[data-namespace=' + namespace + ']';
		} else {
			suffix = ':not([data-namespace])';
		}
		return suffix;
	}

	me.namespaceAttribute = function(element){
		var namespace = $(element).data('namespace');
		var attribute = '';
		if(namespace){
			attribute = 'data-namespace="' + namespace + '"';
		}
		return attribute;
	}

	me.changeOfUploadFiles = function(){

		var selectorSuffix = me.elementToSelectorSuffix(this);
		var namespaceAttribute = me.namespaceAttribute(this);
		var files = $('.uploadFiles' + selectorSuffix).prop('files')
		var $fileNames = $('.fileNames' + selectorSuffix);

		if( !files ){
			files = [ { 'name' : this.value } ];
		}
		var names = $.map(files, function(val) { return val.name; });
		if( names && names.length ){
			
			$fileNames.empty().removeClass('hide');

			$( names ).each( function( ){

				$('.fileNames' + selectorSuffix).append( '<div class="fileToUpload">' +
					"<i class='fa fa-paperclip'></i>" +
					this + '</div>' );
			} );
			$fileNames.append('<button type="button" class="btn btn-default btn-xs fa fa-times removeFilesButton" ' +
				namespaceAttribute + '></button>');
			$('.removeFilesButton' + selectorSuffix).click( me.clickRemoveFilesButton );
		}
	};

	me.clickRemoveFilesButton = function(){

		var selectorSuffix = me.elementToSelectorSuffix(this);
		var control = $(".uploadFiles" + selectorSuffix);
	    control.replaceWith( control = control.clone( true ) );
	    control.val(''); 
	    $('.fileNames' + selectorSuffix).empty();
	    me.observe();
	}

	me.observe = function(){
		$('.uploadLink').off().click( me.clickUploadLink );
		$('.uploadFiles').off().change( me.changeOfUploadFiles );
	};

	me.observe();
}